<template>
  <div class="page">
    <!-- 概览 -->
    <div class="config">
      <div class="flex-align-center">
        <h1>实时概况</h1>
        <span class="update-time">更新时间：{{ detailData.update_time }}</span>
      </div>
      <div class="row" v-if="detailData">
        <div class="col-md-3">
          <div>
            <p class="p-gray">新增用户数</p>
            <p class="p-black-big">
              {{ detailData.user.today_count }}
              <span v-if="detailData.user.status == 1">
                <img src="@/modules/overview/assets/images/xiajiang.png" />
              </span>
              <span v-if="detailData.user.status == 2">
                <img src="@/modules/overview/assets/images/tisheng.png" />
              </span>
            </p>
            <p class="p-grays">昨日：{{ detailData.user.yesterday_count }}</p>
          </div>
        </div>
        <div class="col-md-3">
          <div>
            <p class="p-gray">新增学员数</p>
            <p class="p-black-big">
              {{ detailData.study_user.today_count }}
              <span v-if="detailData.study_user.status == 1">
                <img src="@/modules/overview/assets/images/xiajiang.png" />
              </span>
              <span v-if="detailData.study_user.status == 2">
                <img src="@/modules/overview/assets/images/tisheng.png" />
              </span>
            </p>
            <p class="p-grays">
              昨日：{{ detailData.study_user.yesterday_count }}
            </p>
          </div>
        </div>
        <div class="col-md-3">
          <div>
            <p class="p-gray">累计用户数</p>
            <p class="p-black-big">
              {{ detailData.user.today_count }}
            </p>
          </div>
        </div>
        <div class="col-md-3">
          <div>
            <p class="p-gray">累计学员数</p>
            <p class="p-black-big">
              {{ detailData.study_user.today_count }}
            </p>
          </div>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="col-md-3">
          <div>
            <p class="p-gray">新增内容数</p>
            <p class="p-black-big">
              {{ detailData.content.today_count }}
              <span v-if="detailData.content.status == 1">
                <img src="@/modules/overview/assets/images/xiajiang.png" />
              </span>
              <span v-if="detailData.content.status == 2">
                <img src="@/modules/overview/assets/images/tisheng.png" />
              </span>
            </p>
            <p class="p-grays">
              昨日：{{ detailData.content.yesterday_count }}
            </p>
          </div>
        </div>
        <div class="col-md-3">
          <div>
            <p class="p-gray">新增访问量</p>
            <p class="p-black-big">
              {{ detailData.view.today_count }}
              <span v-if="detailData.view.status == 1">
                <img src="@/modules/overview/assets/images/xiajiang.png" />
              </span>
              <span v-if="detailData.view.status == 2">
                <img src="@/modules/overview/assets/images/tisheng.png" />
              </span>
            </p>
            <p class="p-grays">昨日：{{ detailData.view.yesterday_count }}</p>
          </div>
        </div>
        <div class="col-md-3">
          <div>
            <p class="p-gray">累计内容数</p>
            <p class="p-black-big">
              {{ detailData.content.today_count }}
            </p>
          </div>
        </div>
        <div class="col-md-3">
          <div>
            <p class="p-gray">累计访问量</p>
            <p class="p-black-big">
              {{ detailData.view.today_count }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <div class="echart-box">
      <div class="title-box">学习情况</div>
      <div class="content" ref="chartContent" v-loading="loading">
      </div>
    </div>
  </div>
</template>

<script>
import ListLayout from '../../../base/layout/ListLayout'
import {
  getOverview,
  getStudyCondition,
  getUserGrowth,
} from '../api/college-study-overview'
import MyBarChart from '@/modules/organization-admin/assets/echarts/bar-chart.js'
export default {
  components: { ListLayout },
  data() {
    return {
      filterForm: {},
      // 详情
      detailData: '',
      // 学习情况
      studyConditionData: '',
      studyConditionOptions: {
        start_time: -1, //当开始时间和结束时间间隔等于一天时接口返回的数据title是小时，否则都是返回日期
        end_time: -1,
      },
      // 用户增长
      userGrowthData: '',
      loading: false,
      chartData: [],
      xData: []
    }
  },
  methods: {
    // 获取详情
    getDetail() {
      getOverview().then((res) => {
        this.detailData = res.data
        console.log(this.detailData)
      })
    },
    // 获取学习情况
    getStudyConditionData() {
      this.loading = true
      getStudyCondition(this.studyConditionOptions).then((res) => {
        this.studyConditionData = res.data.list
        this.loading = false

        this.transformData()

        console.log(this.studyConditionData)
        this.initChart()
      }).catch(() => {
        this.loading = false
      })
    },
    // 获取用户增长
    getUserGrowthData() {
      getUserGrowth().then((res) => {
        this.userGrowthData = res.data
        console.log(this.userGrowthData)
      })
    },
    //转换数据格式
    transformData() {
      this.chartData = this.studyConditionData.map((item) => item.value);
      this.xData = this.studyConditionData.map((item) => item.title);
    },
    //初始化图表
    initChart() {
      if (!this.$chart) {
        try {
          this.$chart = new MyBarChart({
            container: this.$refs.chartContent,
            data: this.chartData,
            xData: this.xData,
          });
        } catch (e) {
          console.log(e);
        }
      } else {
        this.setChartOption();
      }
    },
    //设置图表
    setChartOption() {
      this.$chart.setOption(this.xData, this.chartData);
    },
    //销毁实例
    destroyChart() {
      if (this.$chart) {
        this.$chart.dispose();
      }
    },
  },
  mounted() {
    this.getDetail()
    this.getStudyConditionData()
  },
}
</script>

<style lang="scss" scoped>
.config {
  margin-bottom: 24px;
  padding: 27px 21px;
  background: #ffffff;
  border-radius: 2px;

  h1 {
    font-size: 24px;
    font-weight: 500;
    color: rgba(33, 33, 33, 1);
    line-height: 1.5rem;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }

  .el-divider {
    position: absolute;
    height: calc(100% - 40px);
    left: 46%;
    top: 50%;
    transform: translateY(-46%);
    margin: 0;
  }

  .right-top-btn {
    padding: 0;
    margin-left: auto;
    color: #3479ef;
    font-size: 14px;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    padding: 31px 27px 9px;
  }

  .update-time {
    font-size: 18px;
    font-weight: 400;
    color: #999999;
    margin-left: 8px;
  }

  .p-gray {
    font-size: 14px;
    font-weight: 400;
    color: #888888;
  }

  .p-black-big {
    font-size: 26px;
    font-weight: 500;
    color: rgba(37, 38, 49, 1);
    margin-top: 23px;

    span {
      display: inline-block;
      transform: translateY(-3px);

      img {
        width: 12px;
        height: 15px;
      }
    }
  }

  .p-grays {
    margin-top: 6px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
  }

  .flexs {
    display: flex;
    align-items: center;
  }

  .colors {
    width: 12px;
    height: 12px;
    border: 3px;
  }

  .font-colors {
    color: #344563;
    font-size: 14px;
    margin-left: 10px;
  }

  .whole-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px;
  }

  .main-container {
    background-color: #fff;
    margin-top: 15px;
    overflow: hidden;
  }

  .main-header {
    margin-bottom: 15px;
  }

  .main-header>span:first-child {
    font-size: 21px;
    font-weight: 500;
    color: rgba(33, 33, 33, 1);
    line-height: 29px;
  }

  .main {
    /*height: 63vh;*/
    height: 270px;
    margin: 24px 0 0 !important;
  }

  .main-header {
    margin-bottom: 15px;
  }

  .main-header>span:first-child {
    font-size: 21px;
    font-weight: 500;
    color: rgba(33, 33, 33, 1);
    line-height: 29px;
  }
}

.echart-box {
  margin-top: 12px;
  background-color: #fff;
  border-radius: 24px;
  padding: 24px;
  .title-box {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 24px;
  }

  .content {
    width: 100%;
    height: 500px;
    margin: 0 auto;
  }
}
</style>
